





































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import InfraCommandResponseModel from "@/application-manager/models/InfraCommandResponseModel";

@Component
export default class InfraCommandsSideBar extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: false }) isSideBarPinned!: boolean;

  isOpenCollapse = false;
  search = "";

  get infraCommands(): Array<InfraCommandResponseModel> {
    return this.$store.state.infraCommandsStore.infraCommands.filter(
      (item: InfraCommandResponseModel) =>
        item.name.toLowerCase().includes(this.search.toLowerCase())
    );
  }

  get isLocalSideBarVisible(): boolean {
    return this.value;
  }

  set isLocalSideBarVisible(value: boolean) {
    this.$emit("input", value);
  }

  getMandatoryParams(item: InfraCommandResponseModel): Array<string> {
    const mandatoryParams = [];

    if (item.parameters.execute.mandatory && item.parameters.execute.name) {
      mandatoryParams.push(item.parameters.execute.name);
    }
    if (
      item.parameters.rollback.mandatory &&
      item.parameters.rollback.name &&
      !mandatoryParams.includes(item.parameters.rollback.name)
    ) {
      mandatoryParams.push(item.parameters.rollback.name);
    }

    return mandatoryParams;
  }

  getOtherParams(item: InfraCommandResponseModel) {
    return item.params.filter(
      (param: string) =>
        this.getMandatoryParams(item).length &&
        !this.getMandatoryParams(item).includes(param)
    );
  }

  isComment(example: string): boolean {
    return example.startsWith("//");
  }

  @Emit("click:command") emitClickToCommand(value: string) {
    return value;
  }

  @Emit("change:side-bar-pinned") emitChangeSideBarPinned() {
    return;
  }
}
