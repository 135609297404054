




































import { Component, Watch, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import JurisdictionForm from "@/application-manager/components/JurisdictionForm.vue";
import JurisdictionValuesTable from "@/application-manager/components/JurisdictionValuesTable.vue";
import InfraCommandsSideBar from "@/application-manager/components/InfraCommandsSideBar.vue";
import JurisdictionRequestModel from "@/application-manager/models/JurisdictionRequestModel";
import JurisdictionResponseModel from "@/application-manager/models/JurisdictionResponseModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";

@Component({
  components: {
    JurisdictionForm,
    JurisdictionValuesTable,
    InfraCommandsSideBar,
  },
})
export default class JurisdictionView extends mixins(UnsavedChangesMixin) {
  @Ref("infraCommandsSideBar") infraCommandsSideBar!: any;

  localJurisdiction = new JurisdictionRequestModel();
  isLoading = true;
  isWatchedJurisdiction = false;
  isSideBarVisible = false;
  isSideBarPinned = false;
  containerPaddingRight = 32;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localJurisdiction);
  }

  get jurisdictionId(): string {
    return this.$route.params.jurisdictionId;
  }

  get jurisdiction(): JurisdictionResponseModel {
    return this.$store.state.jurisdictionStore.jurisdiction;
  }

  @Watch("jurisdiction", { deep: true })
  private watchJurisdiction(jurisdiction: JurisdictionResponseModel) {
    this.localJurisdiction = JurisdictionRequestModel.ofSource(jurisdiction);

    if (!this.isWatchedJurisdiction) {
      this.isWatchedJurisdiction = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.$store.dispatch("loadJurisdiction", this.jurisdictionId),
      this.$store.dispatch("loadInfraCommands"),
    ]);
    this.isLoading = false;

    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.jurisdiction.jurisdictionName") +
        this.jurisdiction.name ?? ""
    );
    this.handleWatchingUnsavedChanges();
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  handleChangeSideBarPinned() {
    this.isSideBarPinned = !this.isSideBarPinned;

    if (this.isSideBarPinned) {
      this.containerPaddingRight += this.infraCommandsSideBar.$el.clientWidth;
    } else {
      this.containerPaddingRight -= this.infraCommandsSideBar.$el.clientWidth;
    }
  }

  async handleSave(payload: JurisdictionRequestModel) {
    await this.$store.dispatch("updateJurisdiction", payload);
    this.isSavedForm = true;
  }
}
